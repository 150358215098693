<template>
  <div class="Gen-AI">
    <chat-bot
      :loading="isLoading"
      :prompt="prompt"
      :list="list"
      @send="sendMsg"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  components: {
    ChatBot: () => import("@/components/sections/ChatBot.vue"),
  },
  props: {
    options: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    ...mapGetters({
      isLoading: "generativeAI/getLoading",
      prompt: "generativeAI/getTripDetailPrompt",
      list: "generativeAI/getTripDetailList",
    }),

    tripID() {
      return this.$route.query.trip_id;
    },
  },
  mounted() {
    const prompt = [
      {
        id: `Give me an overall summary of all the trips by the vehicle with id:{request.GET.get("vehicle_id")} on the date: {request.GET.get("date")}, return your response in 2 lines`,
        name: "summarize detail of the trip",
      },
    ];
    this.$store.dispatch("generativeAI/tripDetailPrompt", prompt);
  },
  methods: {
    sendMsg(value) {
      const params = {
        ...value,
        trip_id: this.tripID,
        // date: this.options.date,
        date: this.getCurrentDate(),
      };
      this.$store.dispatch("generativeAI/tripDetailList", params);
    },
    getCurrentDate() {
      const dt = new Date();
      const y = dt.getFullYear();
      let m = dt.getMonth() + 1;
      m = m < 10 ? `0${m}` : m;

      let d = dt.getDate();
      d = d < 10 ? `0${d}` : d;

      return `${y}-${m}-${d}`;
    },
  },
};
</script>
<style lang="sass" scoped>
// .Gen-AI
</style>
